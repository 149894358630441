import React from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';

import congesService from '@data/congesService';

import { ButtonFirstAction, ButtonSecondAction } from '@components/UI/Buttons';
import ModalCustom from '@components/UI/ModalCustom';
import InputCustom from '@components/UI/InputCustom';
import SwalCustom from '@helpers/SwalCustom';
import ToastCustom from '@components/UI/ToastCustom';

const UpdateModal = ({
  getTags,
  tags,
  isOpen,
  onModalClose,
  getUserCounters,
  setTags,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (values, props) => {
    props.setSubmitting(true);

    const resources = (resources) => {
      const data = {};
      resources.tags.forEach((resource) => {
        data[resource.id] = {
          label: resource.label,
        };
      });
      return data;
    };

    const data = {
      resources: resources(values),
    };

    congesService
      .patch(`/v1/tags/batch`, data)
      .then((res) => {
        setTags(res.data.data);
        getUserCounters();
        ToastCustom.validated(t('updateSuccess'));
        onModalClose();
      })
      .catch((err) => console.error(err))
      .finally(() => props.setSubmitting(false));
  };

  const handleDeleteTag = (id) => {
    SwalCustom.delete(
      () => {
        congesService
          .delete(`v1/tags/${id}`)
          .then(() => {
            getUserCounters();
            getTags();
            onModalClose();
            ToastCustom.error(t('successDelete'));
          })
          .catch((err) => console.error(err));
      },
      {
        title: t('areYouSureContinue'),
        text: t('deleteAreForever'),
      }
    );
  };

  return (
    <ModalCustom
      isOpen={isOpen}
      customClass={'w-8/12 md:w-[900px]'}
      onModalClose={onModalClose}
      title={t('updateTeam')}
      children={
        <div className='mt-6'>
          <Formik
            initialValues={{
              tags: tags,
            }}
            onSubmit={handleSubmit}
            validate={(values) => {
              let errors = {};
              values.tags.forEach((tag, index) => {
                if (!tag.label) {
                  errors = {
                    ...errors,
                    [`tags[${index}].label`]: 'requiredField',
                  };
                }
              });
              console.error(errors);
              return errors;
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(props) => (
              <Form>
                <div className='flex flex-wrap justify-between gap-y-2'>
                  {tags.length > 0 ? (
                    tags.map((tag, index) => {
                      return (
                        <div
                          className='mr-3 mb-3 w-5/12 flex gap-x-2 items-center'
                          key={index}
                        >
                          <InputCustom
                            type='text'
                            errors={props.errors}
                            containerClass='w-10/12'
                            value={props.values.tags[index].label}
                            name={`tags[${index}].label`}
                            onChange={(e) => {
                              props.setErrors({});
                              props.setFieldValue(
                                `tags[${index}].label`,
                                e.target.value
                              );
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faTrash}
                            className='text-red-500 cursor-pointer'
                            onClick={(e) => {
                              handleDeleteTag(tag.id);
                            }}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <span>{t('youHaveNoTeamYet')}</span>
                  )}
                </div>
                <div className='flex justify-end gap-x-5 mt-6'>
                  <ButtonFirstAction type='button' onClick={onModalClose}>
                    {t('cancel')}
                  </ButtonFirstAction>
                  <ButtonSecondAction
                    type='submit'
                    disabled={props.isSubmitting || !props.values.tags.length}
                  >
                    {t('save')}
                  </ButtonSecondAction>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  );
};

export default UpdateModal;
